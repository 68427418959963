import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import { Strings } from "@/resources";
import CircularLoader from "@/Components/CircularLoader";
import downloadIcon from "../../../images/Download.svg";
import "./button.scss";

export default function Button({
  className = "",
  disabled,
  children,
  loading,
  transparent = false,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  transparent?: boolean;
}) {
  return (
    <button
      {...props}
      className={`${transparent ? "" : "btn"} ${className ? className : ""} ${disabled ? "disabled" : ""}`}
      disabled={disabled}
    >
      {children} {loading && <CircularLoader />}
    </button>
  );
}

export function DownloadButton ({
  text,
  className = "",
  withIcon = true,
  ...props
} : AnchorHTMLAttributes<HTMLAnchorElement> & {
  text?: string;
  withIcon?: boolean;
}) {
  return (
      <a
        {...props}
        className={`download-template-btn ${className}`}
      >
        {withIcon ? (
          <img src={downloadIcon} alt="icon" className="img-fluid" />
        ) : null}
        
        <span>{text ?? Strings.download_template}</span>
      </a>
  );
}
